import { FunctionComponent } from 'react';

import RoutePaths from './route-paths';
import { AboutUs, ContactsContainer, DonateContainer, HumanitarianAidContainer, LegalContainer, ResourcesContainer } from '../pages';

export interface Route {
  name: string;
  path: RoutePaths;
  component: FunctionComponent<any> | null;
}

export const DEFAULT_ROUTE = RoutePaths.HumanitarianAid;

export const routes: Route[] = [
  {
    name: 'About',
    path: RoutePaths.About,
    component: AboutUs,
  },
  {
    name: 'Donate',
    path: RoutePaths.Donate,
    component: DonateContainer,
  },
  {
    name: 'Our Impact',
    path: RoutePaths.HumanitarianAid,
    component: HumanitarianAidContainer,
  },
  {
    name: 'Events',
    path: RoutePaths.Events,
    component: null,
  },
  {
    name: 'Resources',
    path: RoutePaths.Resources,
    component: ResourcesContainer,
  },
  // {
  //   name: 'Legal',
  //   path: RoutePaths.Legal,
  //   component: LegalContainer,
  // },
  // {
  //   name: 'Become A Volunteer',
  //   path: RoutePaths.BecomeAVolunteer,
  //   component: null,
  // },
  {
    name: 'Contacts',
    path: RoutePaths.Contacts,
    component: ContactsContainer,
  },
];
