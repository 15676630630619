import React, { FC } from 'react';
import classNames from 'classnames';
import RoutePaths from '../../routes/route-paths'
import { RedirectLink } from '../RedirectLink'
import { ReactComponent as IconArrow} from '../../assets/icon-arrow.svg';

import styles from './HomeCards.module.scss';

const cards = [
  { title: 'DONATE', route: RoutePaths.Donate, picture: 'donate' },
  { title: 'HUMANITARIAN AID', route: RoutePaths.HumanitarianAid, picture: 'aid' },
  { title: 'EVENTS', route: RoutePaths.Events, picture: 'events' },
  { title: 'LEGAL', route: RoutePaths.Legal, picture: 'legal' },
  { title: 'RESOURCES', route: RoutePaths.Resources, picture: 'resources' },
  { title: 'Contacts', route: RoutePaths.Contacts, picture: 'contact' },
  // { title: 'about us', route: RoutePaths.About, picture: 'mfa' },
  // { title: 'Become a volunteer', route: RoutePaths.Events, picture: 'volunteer' },
  // { title: 'events', route: '', picture: 'events' }
]

export const HomeCards: FC<{}> = () => (
  <section className={styles.wrapper}>
    {cards.map(el => (
      <div className={styles.cardWrapper} key={el.title}>
        <div className={classNames(styles.card, styles[el.picture])}>
          <RedirectLink className={styles.redirectLinkWrapper} title={< >{el.title} <IconArrow className={styles.icon}/></>} href={el.route}/>
        </div>
      </div>
    ))}
  </section>
);
