
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './NavBar.module.scss';
import logo from '../../assets/ur_white_transp1.svg';
import menuIcon from '../../assets/burger.svg';
import closeIcon from '../../assets/close.svg';

import { routes } from '../../routes';
import { useState } from 'react';

export const MobileNavBar: FC = () => {
    const [showMenu, setShowMenu] = useState(false)
    return (
        <div className={styles.root}>
            <NavLink to='/'>
                <img src={logo} alt='' className={styles.logo} />
            </NavLink>
            <div>
                <img 
                    src={showMenu ? closeIcon : menuIcon} 
                    alt='' 
                    className={showMenu ? styles.closeIcon : styles.burgerIcon}  
                    onClick={() => setShowMenu(!showMenu)}
                />
            </div>
            {showMenu && <ul className={styles.mobileNavbar}>
                    {routes.map(route => (
                        <li>
                            <NavLink 
                                to={route.path} 
                                className={(navData) => navData.isActive ? styles.active : styles.inactive } 
                                onClick={() => setShowMenu(!showMenu)}
                            >
                                {route.name}
                            </NavLink>
                        </li>
                    ))}
            </ul>}
        </div>
    );
}