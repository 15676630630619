import React from "react";
import classNames from 'classnames';

import styles from './MainHeader.module.scss';

interface MainHeaderProps {
    title: string;
    subtitle?: string;
    className?: string;
}

export const MainHeader = ({ title, subtitle, className }: MainHeaderProps) => (
    <section className={classNames(styles.container, className)}>
        <h1 className={styles.title}>{ title }</h1>
        { !!subtitle && <h3 className={styles.subTitle}>{ subtitle }</h3> }
    </section>
)