import React, { FC } from "react";
import { MainHeader, SubTitle } from "../components";
import { GoogleForm, googleFormsToJson } from 'react-google-forms-hooks'

import styles from './Pages.module.scss'
import { useState } from "react";
import { useEffect } from "react";

export const AddVolunteerContainer: FC = () => {
    const [formJson, setFormJson] = useState<any | null>(null);
    const fetchFormData = async () => {
        try{
            // const result = await googleFormsToJson(
            //     'https://forms.googleapis.com/v1/forms/19mWfgj7zpYT27tuStscdbYIgJxqsvSPVzaTqlt5v100'
            // );
            const result = await fetch('https://forms.googleapis.com/v1/forms/19mWfgj7zpYT27tuStscdbYIgJxqsvSPVzaTqlt5v100');
            setFormJson(result);
        } catch(error) {
            console.log(error);
        }

    }
    useEffect(() => {
        fetchFormData();
    }, [])

    console.log(formJson);

    return (
    <section className={styles.container}>
        <MainHeader title="Contacts" className={styles.contactsBackground} />
        <section className={styles.contentContainer}>
            <SubTitle text="talk to us" />
            <h3 className={styles.subheader}>Don’t hesitate to contact us for any matter we can be potentially helpful with.</h3>
            <section>
                form
            </section>
        </section>
    </section>
)};