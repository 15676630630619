import React from "react";

import styles from './Pages.module.scss';

import { MainHeader, SubTitle, RedirectLink } from '../components';

export const ResourcesContainer = () => (
    <section className={styles.container}>
        <MainHeader title="Resources" className={styles.resourcesBackground} />
        <section className={styles.contentContainer}>
            <SubTitle text="FOLLOW OFFICIAL RESOURCES AND SHARE VERIFIED INFORMATION" />
            <h3 className={styles.subheader}>Make sure you follow only the official resources to stay up to date and get only the verified information.</h3>
            <RedirectLink title="see the list" href="https://linktr.ee/ukraine_ua" className={styles.resourcesButton} />
        </section>
    </section>
);