import React, { FC } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { NavBar, Footer } from './components';
import { AboutUs, ContactsContainer, DonateContainer, Home, HumanitarianAidContainer, LegalContainer, ResourcesContainer, AddVolunteerContainer, EventsContainer } from './pages';
import RoutePaths from './routes/route-paths';

import './styles/global.scss';

const AppComponent: FC = () => (
  <BrowserRouter>
    <NavBar />
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path={RoutePaths.About} element={<AboutUs/>} />
      <Route path={RoutePaths.Contacts} element={<ContactsContainer/>} />
      <Route path={RoutePaths.Donate} element={<DonateContainer/>} />
      <Route path={RoutePaths.HumanitarianAid} element={<HumanitarianAidContainer/>} />
      <Route path={RoutePaths.Legal} element={<LegalContainer/>} />
      <Route path={RoutePaths.Resources} element={<ResourcesContainer/>} />
      <Route path={RoutePaths.Events} element={<EventsContainer/>} />
      <Route path={RoutePaths.AddVolunteer} element={<AddVolunteerContainer/>} />
    </Routes>
    <Footer/>
  </BrowserRouter>
);

export default AppComponent;
