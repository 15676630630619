import React, { FC } from "react";
import { MainHeader, SubTitle } from "../components";

import styles from './Pages.module.scss'

export const ContactsContainer: FC = () => (
    <section className={styles.container}>
        <MainHeader title="Contacts" className={styles.contactsBackground} />
        <section className={styles.contentContainer}>
            <SubTitle text="talk to us" />
            <h3 className={styles.subheader}>Don’t hesitate to contact us for any matter we can be potentially helpful with.</h3>
            <section>
                <p className={styles.sectionDescription }><b>Phone</b> – <a href="tel:+12246344555">#224-634-4555</a></p>
                <p className={styles.sectionDescription }><b>Fax</b> – <a href="tel:+12243851885">#224-385-1885</a></p>
                <p className={styles.sectionDescription }><b>E-mail</b> – <a href="mailto:we@ukrainiansunited.org">we@ukrainiansunited.org</a></p>
                <p className={styles.sectionDescription }><b>Social Networks</b> – <a href="https://www.facebook.com/ukrainiansunited">Facebook</a></p>
            </section>
        </section>
    </section>
);