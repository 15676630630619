import React from 'react';

import styles from './Pages.module.scss';

import { Accordion, MainHeader, SubTitle } from '../components';

export const LegalContainer = () => (
    <section className={styles.container}>
        <MainHeader title="Legal" className={styles.legalBackground} />
        <section className={styles.contentContainer}>
            <SubTitle text="we are ready to help" />
            <h3 className={styles.subheader}>The information provided on this website is not intended to, constitute legal advice; instead, all the information, content, and materials available here are for general informational purposes only</h3>
            <h3 className={styles.subheader}>Below is a list of organizations helping to connect potential clients with legal assistance.</h3>
            <Accordion title="If you are a U.S. citizen with a Ukrainian spouse or child seeking to travel to the United States on a nonimmigrant visa">
                <ul className={styles.list}>
                    <li>If you already have an appointment at U.S. Embassy Warsaw but wish to expedite it, you may <a href="https://www.ustraveldocs.com/pl/en/expedited-appointment">request expedition</a></li>
                    <li>If you already began a nonimmigrant visa application process in Ukraine, you may send a request to Support-Poland https://protect-us.mimecast.com/s/CKFyCkRwQkSoPQgNiVbiuQ?domain=ustraveldocs.com to ask for transfer of your “profile” to Warsaw, after which you should be able to continue the process for a Warsaw appointment.</li>
                    <li>If you are seeking but do not already have an appointment: <br/>
                        <ul className={styles.list}>
                            <li>– If you will apply in Warsaw:  <a href="https://www.ustraveldocs.com/pl/en/step-4">Sign up for a "C1/D" (crew) appointment</a>, even though you are not a crew member.  When you come to the Embassy we will convert your appointment to the proper type.</li>
                            <li>– If you will apply in Krakow:  Visit https://www.ustraveldocs.com/pl/en/step-4 and sign up for an "F" (student) appointment, even though you are not a student.  When you come to the Consulate we will convert your appointment to the proper type.</li>
                        </ul>
                    </li>
                    <li>If you are unable to schedule an appointment via this method, you may instead <a href="https://ustraveldocs.com/pl/en/group-appointments">request a “group appointment”</a> and suggest a date convenient for you.  Once you have submitted your request, please wait for a response from the Embassy, which will come via email and should permit you to schedule an appointment.</li>
                </ul>
            </Accordion>
            <Accordion title="If you are a Ukrainian who wants to travel to the United States or a U.S. citizen who has friends, business associates, or NON-IMMEDIATE family who want to travel to the United States">
                <ul className={styles.list}>
                    <li>If the traveler already has an appointment at U.S. Embassy Warsaw but wishes to expedite it, you may <a href="https://www.ustraveldocs.com/pl/en/expedited-appointment">request expedition.</a></li>
                    <li>If the traveler already began a nonimmigrant visa application process in Ukraine, you may send a request to Support-Poland@https://protect-us.mimecast.com/s/CKFyCkRwQkSoPQgNiVbiuQ?domain=ustraveldocs.com to ask for transfer of your “profile” to Warsaw, after which you should be able to continue the process for a Warsaw appointment.</li>
                    <li>If the traveler is seeking but does not already have an appointment at U.S. Embassy Warsaw or U.S. Consulate Krakow, you may try <a href="https://www.ustraveldocs.com/pl/en/step-4">to schedule one.</a>  However, availability is extremely limited.</li>
                </ul>
            </Accordion>
            <Accordion title="If you are seeking information about immediate humanitarian assistance in Poland">
                <ul className={styles.list}>
                    <li>Almost all refugee cases in countries abroad are first processed by local authorities, in this case the government of Poland. </li>
                    <li>Please <a href="https://www.gov.pl/web/udsc/ukraina-en">visit the government of Poland webpage</a> for information about resources: https://www.gov.pl/web/udsc/ukraina-en. This website provides the locations of official reception points along Poland’s border with Ukraine.</li>
                    <li>In Warsaw, those seeking information on support from the Polish government can direct inquires to the Office for Foreigners (Urząd do Spraw Cudzoziemców).</li>
                </ul>
            </Accordion>
        </section>
    </section>
);