import React from "react";
import { Carousel }  from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from './Pages.module.scss';

import { MainHeader, SubTitle, RedirectLink } from  '../components';
import carouselImg1 from '../assets/our-impact/1.jpeg';
import carouselImg2 from '../assets/our-impact/2.jpeg';
import carouselImg3 from '../assets/our-impact/3.jpeg';
import carouselImg4 from '../assets/our-impact/4.jpeg';
import carouselImg5 from '../assets/our-impact/5.jpeg';
import carouselImg6 from '../assets/our-impact/6.jpeg';
import carouselImg7 from '../assets/our-impact/7.jpeg';
import carouselImg8 from '../assets/our-impact/8.jpeg';
import carouselImg9 from '../assets/our-impact/9.jpeg';
import carouselImg10 from '../assets/our-impact/10.jpeg';
import carouselImg11 from '../assets/our-impact/11.jpeg';
import carouselImg12 from '../assets/our-impact/12.jpeg';
import carouselImg13 from '../assets/our-impact/13.jpeg';
import carouselImg14 from '../assets/our-impact/14.jpeg';
import carouselImg15 from '../assets/our-impact/15.jpeg';
import carouselImg16 from '../assets/our-impact/16.jpeg';
import carouselImg17 from '../assets/our-impact/17.jpeg';
import carouselImg18 from '../assets/our-impact/18.jpeg';
import carouselImg19 from '../assets/our-impact/19.jpeg';
import carouselImg20 from '../assets/our-impact/20.jpeg';
import carouselImg21 from '../assets/our-impact/21.jpeg';
import carouselImg22 from '../assets/our-impact/22.jpeg';
import carouselImg23 from '../assets/our-impact/23.jpeg';
import carouselImg24 from '../assets/our-impact/24.jpeg';
import carouselImg25 from '../assets/our-impact/25.jpeg';
import carouselImg26 from '../assets/our-impact/26.jpeg';
import carouselImg27 from '../assets/our-impact/27.jpeg';
import carouselImg28 from '../assets/our-impact/28.jpeg';
import carouselImg29 from '../assets/our-impact/29.jpeg';
import carouselImg30 from '../assets/our-impact/30.jpeg';
import carouselImg31 from '../assets/our-impact/31.jpeg';
import carouselImg32 from '../assets/our-impact/32.jpeg';
import carouselImg33 from '../assets/our-impact/33.jpeg';
import carouselImg34 from '../assets/our-impact/34.jpeg';
import carouselImg35 from '../assets/our-impact/35.jpeg';
import carouselImg36 from '../assets/our-impact/36.jpeg';
import carouselImg37 from '../assets/our-impact/37.jpeg';

interface CarouselImg {
    src: string;
}

const carouselImgs: CarouselImg[] = [
    {src: carouselImg1},
    {src: carouselImg2},
    {src: carouselImg3},
    {src: carouselImg4},
    { src: carouselImg5},
    { src: carouselImg6},
    { src: carouselImg7},
    { src: carouselImg8},
    { src: carouselImg9},
    { src: carouselImg10},
    { src: carouselImg11},
    { src: carouselImg12},
    { src: carouselImg13},
    { src: carouselImg14},
    { src: carouselImg15},
    { src: carouselImg16},
    { src: carouselImg17},
    {src: carouselImg18},
    {src: carouselImg19},
    {src: carouselImg20},
    {src: carouselImg21},
    {src: carouselImg22},
    {src: carouselImg23},
    {src: carouselImg24},
    {src: carouselImg25},
    {src: carouselImg26},
    {src: carouselImg27},
    {src: carouselImg28},
    {src: carouselImg29},
    {src: carouselImg30},
    {src: carouselImg31},
    {src: carouselImg32},
    {src: carouselImg33},
    {src: carouselImg34},
    {src: carouselImg35},
    {src: carouselImg36},
    {src: carouselImg37}
];

export const HumanitarianAidContainer = () => (
    <section className={styles.container}>
        <MainHeader title="Our Impact" className={styles.humanitarianAidBackground} />
        <section className={styles.contentContainer}>
            <SubTitle text="what we did" />
            <h3 className={styles.subheader}>With the support of your donations we’ve managed to help several military units who are fighting for the Ukrainian freedom. </h3>
            <section className={styles.ourImpactCarousel}>
                <Carousel infiniteLoop useKeyboardArrows swipeable showThumbs={false} showArrows={false} showStatus={false}>
                    { carouselImgs.map((img) => (
                        <div key={img.src} className={styles.carouselImageWrapper}><img src={img.src} alt="" className={styles.carouselImage}/></div>
                    )) }
                </Carousel>
            </section>
            <SubTitle text="Latest needs & requests" />
            <h3 className={styles.subheader}>You can donate our to the Ukrainian resistance or learn more about latest requests from Ukraine by connecting to the Midwest initiative and volunteers.</h3>
            <section className={styles.eventsButtonWrapper}>
                <RedirectLink title="donate" href="https://ukrainiansunited.org/donate" className={styles.eventsButton} />
                <RedirectLink title="learn more" href="https://t.me/help_razom_chanel" className={styles.learnMore} />
            </section>
        </section>
    </section>
);