import React, { FC, useState } from 'react';
import Carousel from 'nuka-carousel';
import classNames from 'classnames';

import styles from './HomeGallery.module.scss';

import {ReactComponent as Squere } from '../../assets/pagination.svg';

const galleryContent = [
  { 
    picture: 'tank',
    titleLeft: 'UNITED TO',
    titleRight: 'STOP WAR',
    subTitle: 'The unprovoked Russian military invasion of Ukraine on February 24th, 2022 has caused a humanitarian crisis, forcing civilians to flee their homes or take refuge in bomb shelters and subway stations throughout the country.',
    description: 'We are coordinating efforts to support Ukraine with other non-profit organizations, international funds and government agencies of Ukraine.'
  }, 
  {picture: 'subway', titleLeft: 'UNITED TO', titleRight: 'SAVE LIVES', subTitle: 'The unprovoked Russian military invasion of Ukraine on February 24th, 2022 has caused a humanitarian crisis, forcing civilians to flee their homes or take refuge in bomb shelters and subway stations throughout the country.'}, 
  {picture: 'house', titleLeft: 'UNITED TO', titleRight: 'SAVE LIVES', subTitle: 'The unprovoked Russian military invasion of Ukraine on February 24th, 2022 has caused a humanitarian crisis, forcing civilians to flee their homes or take refuge in bomb shelters and subway stations throughout the country.'}, 
  {picture: 'bucha', titleLeft: 'UNITED TO', titleRight: 'HELP UKRAINE', subTitle: 'The unprovoked Russian military invasion of Ukraine on February 24th, 2022 has caused a humanitarian crisis, forcing civilians to flee their homes or take refuge in bomb shelters and subway stations throughout the country.'}, 
  {picture: 'london', titleLeft: 'UNITED TO', titleRight: 'HELP UKRAINE', subTitle: 'The unprovoked Russian military invasion of Ukraine on February 24th, 2022 has caused a humanitarian crisis, forcing civilians to flee their homes or take refuge in bomb shelters and subway stations throughout the country.'}, 
];

export const HomeGallery: FC<{}> = () => {
  const [ currentSlide, setCurrentSlide ] = useState(0);

  const slideChanges = (index: number) => {
    setCurrentSlide(index);
  }

  const titleLeft = galleryContent[currentSlide].titleLeft;
  const titleRight = galleryContent[currentSlide].titleRight;

  return (
    <section className={styles.carouselContainer}>
      <div className={styles.information}>
        <h1 className={styles.title}>
          <div className={styles.titleLeft}>{titleLeft}</div>
          <div className={styles.titleRight}>
            <a target="_blank" className={styles.titleLink} href='https://war.ukraine.ua/'>
              {titleRight}
            </a>
          </div>
        </h1>
        <h3 className={styles.subTitle}>{galleryContent[0].subTitle}</h3>
        <p className={styles.description}>{galleryContent[0].description}</p>
      </div>
      <Carousel
        afterSlide={i => slideChanges(i)}
        autoplay
        wrapAround
        renderCenterLeftControls={() => null}
        renderCenterRightControls={() => null}
        renderBottomCenterControls={({ slideCount, currentSlide, goToSlide }) =>
                <div className={styles.sliderControl}>
                    <ul>
                        {[...Array(slideCount)].map((e, key) =>
                            <li  key={key}>
                                <button type="button" aria-label="slide 1 bullet" onClick={() => goToSlide(key)}>
                                    <Squere className={currentSlide === key ? `${styles.activePage}` : `${styles.notActivePage}`}/>
                                </button>
                            </li>
                        )}
                    </ul>
                </div >
            }
        >
        {galleryContent.map(item => (
          <div className={styles.pictureContainer}>
            <div className={classNames(styles.picture, styles[item.picture])} />
          </div>
        ))}
      </Carousel>
    </section>
  )
};