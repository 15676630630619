import React from "react";

import styles from './Pages.module.scss';

import { MainHeader, SubTitle, RedirectLink } from '../components';
import eventImg from '../assets/event-banner 2.png';

export const EventsContainer = () => (
    <section className={styles.container}>
        <MainHeader title="Events" className={styles.eventsBackground} />
        <section className={styles.contentContainer}>
            <SubTitle text="upcoming events" />
            <img src={eventImg} alt="upcoming Event" className={styles.eventsImg} />
            <h3 className={styles.subheader}><b>Chicago4Ukraine</b> – Sat, July 30, 2022 7:30 PM</h3>
            <h3 className={styles.subheader}>Exclusive Chicago Gala Event benefiting Ukrainian Resistance, Revived Soldiers Ukraine and Kharkiv special school for visually impaired.</h3>
            <p>With Consulate General of Ukraine in Chicago, Ukrainian Resistance Foundation, City of Chicago Mayor's Office, World Business Chicago, City of Chicago Sister-City Committee, Resilience.</p>
            <p>A-List performances and guests from Ukraine and US, featuring Anastasiya Kamenskyh  + Special Guests.</p>
            <p><b>Our Mission:</b></p>
            <p>To collect $1,000,000+ in donations for Revived Soldiers Ukraine Fund and bring injured Ukrainian soldiers for surgery, treatment, and rehabilitation in the US and Korolenko Kharkiv special school for visually impaired children.</p>
            <section className={styles.eventsButtonWrapper}>
                <RedirectLink title="rsvp here" href="https://www.eventbrite.com/e/chicago-4-ukraine-gala-event-concert-tickets-353900815867" className={styles.eventsButton} />
                <RedirectLink title="learn more" href="https://linktr.ee/ukraine_ua" className={styles.learnMore} />
            </section>
            <SubTitle text="GET THE LATEST UPDATES" />
            <h3 className={styles.subheader}>Check out our Facebook page to learn about the past and upcoming activities we do.</h3>
            <RedirectLink title="our events" href="https://www.facebook.com/ukrainiansunited/events" className={styles.eventsButton} />
        </section>
    </section>
);