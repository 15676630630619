enum RoutePaths {
  About = '/about',
  Contacts = '/contacts',
  Donate = '/donate',
  Events = '/events',
  BecomeAVolunteer = '/become-a-volunteer',
  HumanitarianAid = '/humanitarian-aid',
  Legal = '/legal',
  Resources = '/resources',
  AddVolunteer = '/add-volunteer'
}

export default RoutePaths;
