import React, { FC } from 'react';

import { ReactComponent as InstagramLogo} from '../../assets/icon-instagram.svg'
import { ReactComponent as SpotifyLogo} from '../../assets/icon-spotify.svg'
import { ReactComponent as FBLogo} from '../../assets/icon-fb.svg';
import { ReactComponent as TwitterLogo} from '../../assets/twitter_normal.svg';

import styles from './Footer.module.scss';


export const Footer: FC<{}> = () => {

  return (
  <footer className={styles.wrapper}>
    <div className={styles.footerInfo}>
      We are coordinating efforts to support Ukraine with other non-profit organizations, international funds and government agencies of Ukraine.
    </div>
    <section className={styles.networking}>
      Ukraine on
      <div className={styles.iconContainer}>
        <a className={styles.socialMediaLink} rel="noreferrer" target="_blank" href="https://www.facebook.com/ukrainiansunited/"><FBLogo/></a>
      </div>
      <div className={styles.iconContainer}>
        <a className={styles.socialMediaLink} rel="noreferrer" target="_blank" href="https://www.instagram.com/ukraine.ua/"><InstagramLogo/></a>
      </div>
      <div className={styles.iconContainer}>
        <a className={styles.socialMediaLink} rel="noreferrer" target="_blank" href="https://open.spotify.com/user/hvnyycti3iu4w1kfm58bdulch?si=838b4aa75f1c49f7&nd=1"><SpotifyLogo/></a>
      </div>
      <div className={styles.iconContainer}>
        <a className={styles.socialMediaLink} rel="noreferrer" target="_blank" href="https://twitter.com/ukraine"><TwitterLogo/></a>
      </div>
    </section>
    <section className={styles.emailSection}>
      <div>For inquiries </div>
      <section><a href="mailto:we@ukrainiansunited.org">we@ukrainiansunited.org</a></section>
    </section>
  </footer>
  )
};
