import React, { FC } from "react";
import { MainHeader, SubTitle } from "../components";

import styles from './Pages.module.scss'
import consulate from '../assets/partners/Embassy of Ukraine_EN_UA_DE-3-02 1.png';
import soldiers from '../assets/partners/soldiers.png';
import congress from '../assets/partners/congress-logo.png';
import eleks from '../assets/partners/Screenshot 2022-06-08 at 22.25 1.png';
import help from '../assets/partners/image 5.png';

export const AboutUs: FC = () => (
    <section className={styles.container}>
        <MainHeader title="About" className={styles.aboutBackground} />
        <section className={styles.contentContainer}>
            <SubTitle text="Our mission" />
            <h3 className={styles.subheader}>Our mission is to create a not-for-profit charitable platform of a wide network of NGOs that allows for consolidation of efforts and amplification of humanitarian aid impact focused on funding and providing aid to Ukraine. Our goal is to ensure continuous help to Ukraine and Ukrainians now and in the post-war rebuilding and recovery. </h3>
            <SubTitle text="founders" />
            <h3 className={styles.subheader}>Ellen “Alla” Lopatkina</h3>
            <p className={styles.sectionDescription }>Born and raised in Ukraine. Immigrated to US in 1999. Founder and president of Nortia Logistics Inc, full service innovative transportation company with deep history of charitable work. Mom of a five year old who loves to sing.</p>
            <h3 className={styles.subheader}>Danylo Terleckyj</h3>
            <p className={styles.sectionDescription }>Dan is a trial attorney in Chicago who represents injury and sexual abuse victims. He grew up, the son of an autoworker, in a blue-collar neighborhood in Metro Detroit. A formative experience that lead him to focus his career on helping people.</p>
            <SubTitle text="our partners" />
            <section className={styles.partnersWrapper}>
                <a href="https://chicago.mfa.gov.ua"><img src={consulate} alt="consulate" /></a>
                <a href="https://www.rsukraine.org"><img src={soldiers} alt="revived soldiers" /></a>
                <a href="https://uccaillinois.org"><img src={congress} alt="ukrainian gongress" /></a>
                <a href="https://eleks.com"><img src={eleks} alt="eleks" /></a>
                <a href="https://www.helprazom.com/"><img src={help} alt="help razom" /></a>
            </section>
        </section>
    </section>
);