import React from "react";
import classNames from 'classnames';

import styles from './Pages.module.scss';
import QR from '../assets/paypalqr.jpeg';

import { MainHeader, SubTitle, Accordion, RedirectLink } from '../components';

export const DonateContainer = () => (
    <section className={classNames(styles.container, styles.donate)}>
        <MainHeader title="Donate" className={styles.background}/>
        <section className={styles.contentContainer}>
            <SubTitle text="Donate" />
            <h3 className={styles.subheader}>
                We will be coordinating with a number of partners both in the USA & Europe to provide necessary help.
            </h3>
            <Accordion title="Bank Transfer" className={styles.accordion}>
                <h4 className={styles.sectionTitle }><b>Account info</b></h4>
                <article>Ukrainian Resistance Foundation</article>
                <article>Account –822631922</article>
                <article>Routing number –071000013</article>
            </Accordion>
            <Accordion title="Zelle" className={styles.accordion}>
                <article className={styles.sectionDescription }>You can pay via <b>we@ukrainiansunited.org</b></article>
            </Accordion>
            <Accordion title="PayPal" className={styles.accordion}>
                <article className={styles.sectionDescription }>You can pay via <a href="https://www.paypal.com/donate/?hosted_button_id=TNLFHGCGP6CUS"><b>PayPal</b></a></article>
                <img src={QR} alt="" className={styles.qrImage} />
            </Accordion>
        </section>
    </section>
);