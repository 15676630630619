import React, { FC, useState } from 'react';
import classNames from 'classnames';

import styles from './Accordion.module.scss';
import minus from '../../assets/icon-minus.svg';
import plus from '../../assets/icon-plus.svg';

interface AccordionProps {
    title: string;
    className?: string;
}

export const Accordion: FC<AccordionProps> = ({ title, className, children }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <section className={classNames(styles.container)}>
            <section onClick={() => setExpanded(!expanded)} className={styles.titleSection}>
                <span>{title}</span>
                <img src={expanded ? minus : plus} alt='' className={styles.icon} />
            </section>
            {expanded && (
                <section className={styles.contentSection}>
                    {children}
                </section>
            )}
        </section>
    );
}