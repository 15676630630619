import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './RedirectLink.module.scss';
interface RedirectLinkProps {
    title: string | React.ReactElement;
    href: string;
    className?: string;
}

export const RedirectLink: FC<RedirectLinkProps> = ({ href, title, className }) => (
    <a className={classNames(styles.redirectLink, className)} href={href}>{title}</a>
);