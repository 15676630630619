import React, { FC, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './NavBar.module.scss';
import { ReactComponent as Logo} from '../../assets/ur_white_transp1.svg';

import { routes } from '../../routes';
import { MobileNavBar } from './MobileNavBar';

export const NavBar: FC = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => {
      window.removeEventListener('resize', () => setWidth(window.innerWidth));
    }
  }, []);
  
  return width > 1200 ? (
    <div className={styles.root}>
      <NavLink to='/'>
          <Logo className={styles.logo} />
      </NavLink>
      <ul className={styles.navbar}>
        {routes.map(route => (
          <li>
            <NavLink to={route.path} className={(navData) => navData.isActive ? styles.active : styles.inactive } >
              {route.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  ) : <MobileNavBar />
}