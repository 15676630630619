import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './SubTitle.module.scss';

interface IProps {
    text: string;
    className?: string;
}

export const SubTitle: FC<IProps> = ({ text, className }) => <h2 className={classNames(styles.subtitle, className)}>{text}</h2>;
